import { writable, derived } from "svelte/store"
import Api from "../../api"

export const sampleCount = writable(1)
export const sampleData = writable([])
export const pageState =  writable(0)
export const pendingOrderId =  writable()

export const selectOptions = writable({
  material: '',
  pricing: '',
  cannabis_type: '',
  compliance_type: ''
})

export const saveSampleCount = async (pending_order_id, sample_count) => {
  console.log('[pending order store] saveSampleCount invoked w/ pending_order_id:', pending_order_id, 'pending_order_id:', pending_order_id);
  let result = await Api.get(`/pending_orders/${pending_order_id}/sample_count/${sample_count}/save.json`)

  console.log('[pending order store] saveSampleCount result:' , result)
  sampleCount.set(sample_count);
  return result;
}


export const advancePageState = async pending_order_id => {
  console.log('[pending order store] persistWizardState invoked w/ pending_order_id:', pending_order_id);
  let result = await Api.get(`/pending_orders/${pending_order_id}/next.json`)

  console.log('[pending order store] result:' , result)
  pageState.update( () => { state: result.aasm_state } );
  return result;
}
