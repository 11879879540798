<script>
  import { onMount } from "svelte"
  import { batchStateStore,
           advanceBatchState,
           batchDataStore,
           sampleLineItemStore,
           saveSampleLineItem,
           deleteSampleLineItem } from "../stores"
  import SampleLineItemRow from "./SampleLineItemRow.svelte"

  export let sampleLineItems
  export let batch
  export let tableClass

  batchStateStore.set( { state: batch.aasm_state,
                         humanized: batch.current_state_humanized } )
  console.log('$batchStateStore.state', $batchStateStore.state)
  $: editable = $batchStateStore.state == 'sample_prep'

  onMount(() => {
    sampleLineItemStore.set(sampleLineItems)
    console.log('$sampleLineItemStore:', $sampleLineItemStore)
    console.log('$sampleLineItemStore[0]:', $sampleLineItemStore[0])
    console.log('$sampleLineItemStore[0].batch_mass_complete:', $sampleLineItemStore[0].batch_mass_complete)
    batchDataStore.set( { mass_complete: $sampleLineItemStore[0].batch_mass_complete,
                          methanol_volume_complete: $sampleLineItemStore[0].batch_methanol_volume_complete,
                          moisture_complete: $sampleLineItemStore[0].batch_moisture_complete,
                          sample_line_items_valid: $sampleLineItemStore[0].batch_sample_line_items_valid } )
  })

  // export let onSave
  let error = false
  const handleSave = () => {
    console.log('[SampleLineItemList] handleSave invoked');
    error = false
    advanceBatchState( $sampleLineItemStore[0].batch_id)
  }
  //
</script>

<p>
  <h5><strong>Current Status:</strong> { ($batchStateStore.humanized) }</h5>
<hr/>

<table class="table {editable ? 'table-hover' : 'table-bordered'} { tableClass }">
  <thead>
    <tr>
      <th>Sample</th>
      <th>
        Mass
        { #if editable }
          { @html $batchDataStore.mass_complete == true ?
                  '<i class="anticon anticon-check"></i>' :
                  '' }
        { /if }
      </th>
      <th>
        Methanol volume
        { #if editable }
          { @html $batchDataStore.methanol_volume_complete == true ?
                  '<i class="anticon anticon-check"></i>' :
                  '' }
        { /if }
      </th>
      <th>
        Moisture
        { #if editable }
          { @html $batchDataStore.moisture_complete == true ?
                  '<i class="anticon anticon-check"></i>' :
                  '' }
        { /if }
      </th>
    </tr>
  </thead>
  {#each $sampleLineItemStore as sample_line_item}
    <SampleLineItemRow
      editable = { editable }
      bind:sample_line_item
      onSave={ () => saveSampleLineItem(sample_line_item) }
      onDelete={() => deleteSampleLineItem(sample_line_item)} />
  {/each}
</table>

<p>
  { #if editable}
    { #if $batchDataStore.sample_line_items_valid }
      <button class="btn btn-primary btn-xs" on:click={handleSave}>Sign & Complete</button>
    { :else }
      Please complete all fields to continue.
    { /if }
  { /if }



</p>
