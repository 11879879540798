<script>
  import { onMount } from "svelte"
  import { selectOptions, sampleCount, sampleData } from "./pending_order_wizard_store.js"

  export let onSubmit
  export let onBack

  let material_select_options
  let pricing_select_options
  let cannabis_type_select_options
  let compliance_type_select_options

  function SampleRow(idx){
     this.index = idx;
  }
  SampleRow.prototype.toString = function(){ return this.index; }

  $: sample_list = new Set()
  let sample_data

  onMount(() => {
    console.log('[SampleDetailsPage] onMount invoked')
    console.log('[SampleDetailsPage] sampleCount:')
    addSample( new SampleRow(0) )
  })

  selectOptions.subscribe(store_data => {
    material_select_options = store_data.material;
    pricing_select_options = store_data.pricing;
    cannabis_type_select_options = store_data.cannabis_type;
    compliance_type_select_options = store_data.compliance_type;
	});

  sampleData.subscribe(value => {
    sample_data = value ;
  });

  function addSample(sample_row) {
    console.log('addSample invoked w/ sample_list.size:', sample_list.size)
    let updated_set = new Set(sample_list)
    updated_set.add(sample_row)
    console.log('updated_set:', updated_set)
		sample_list = updated_set
	}

  function deleteRow(sample_row) {
    console.log('deleteRow invoked w/ sample_row:', sample_row)
    console.log('deleteRow invoked w/ sample_list:', sample_list)
    let updated_set = new Set(sample_list)
    // console.log('updated_set.has(sample_row):', updated_set.has(sample_row))
    //
    // updated_set.delete( sample_row )

    updated_set.forEach(sr => function(sr){
      console.log('sr.index:', sr.index)
      console.log('sr.index == sample_row.index:', sr.index == sample_row.index)
    })

    console.log('updated_set:', updated_set)
    sample_list = updated_set
	}

  function onFormSubmit(e) {
    const formData = new FormData(e.target);

    const data = {};
    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }
    sampleData.set(data)
    onSubmit()
  }

</script>

<div class="m-t-5 m-b-5">
   <div class="text-center">
      <h3>Sample &amp; Testing Details</h3>
      <p class="lead text-muted p-b-3">
         We need some details about your samples and the tests you want done.
      </p>
   </div>
   <pre>sample_list: {sample_list}</pre>
   <div class="row">
      <div class="col md-12">
         <div class="card">
            <div class="card-body p-0">
               <h4 class="p-20">Sample Details</h4>
                <form on:submit|preventDefault={onFormSubmit}>
                  <table class="table table-hover m-b-0">
                    <thead>
                      <tr>
                         <th class="text-center"></th>
                         <!-- <th class="text-center">Name</th> -->
                         <th class="text-center">Material</th>
                         <th class="text-center">Package</th>
                         <th class="text-center">Cannabis Type</th>
                         <th class="text-center">Compliance Type</th>
                         <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                    {#each Array.from(sample_list) as sample_row, i}
                      <tr>
                        <td>#&nbsp;{i+1}</td>
                         <!-- <td>
                            <input type="text" name="samples[{i}][name]" id="samples_{i}_name" class="form-control form-control-sm" required="required">
                         </td> -->
                         <td>
                            <select name="samples[{i}][matrix_type_id]" id="samples_{i}_matrix_type_id" required="required" class="form-control form-control-sm">
                               {@html material_select_options }
                            </select>
                         </td>
                         <td>
                            <select name="samples[{i}][pricing_package_id]" id="samples_{i}_pricing_package_id" required="required" class="form-control form-control-sm">
                               {@html pricing_select_options }
                            </select>
                         </td>
                         <td>
                           <select name="samples[{i}][cannabis_type]" id="samples_1_cannabis_type" class="form-control form-control-sm">
                            {@html cannabis_type_select_options }
                           </select>
                         </td>
                         <td>
                            <select name="samples[{i}][compliance_type]" id="samples_1_compliance_type" required="required" class="form-control form-control-sm">
                              {@html compliance_type_select_options }
                            </select>
                         </td>
                        <td>
                          <a class="btn btn-xs btn-danger m-r-5 text-white" on:click={ () => deleteRow(sample_row) }>x</a>
                        </td>
                      </tr>
                    {/each}
                    </tbody>
                    <tfoot>
                      <tr>
                         <td colspan="2">
                            <a class="btn btn-success m-r-5 text-white" on:click={ () => addSample(new SampleRow(sample_list.size)) }>Add Sample</a>
                          </td>
                          <td colspan="4">
                            <div class="text-right">
                               <a class="btn btn-default m-r-5" on:click={ () => onBack }>Back</a>
                               <button class="btn btn-primary" type="submit">Continue</button>
                            </div>
                         </td>
                      </tr>
                  </tfoot>
                </table>
              </form>
            </div>
         </div>
      </div>
   </div>
</div>
