<script>
  export let sample_line_item
  export let onSave
  export let onDelete
  export let editable = true

  let error = false
  const handleSave = () => {
    if(editable){
      console.log('[SampleLineItemRow] handleSave invoked');
      error = false
      onSave().catch(() => (error = true))
    }
  }
  const handleDelete = () => {
    if (confirm("Are you sure?")) {
      onDelete()
    }
  }
</script>

<style>
  /* tr {
    transition: background 1s ease;
  } */
  .error {
    background: LightCoral;
  }
</style>

<tr class:error>
  <td>{ sample_line_item.sample_name }</td>
  <td>
    { #if editable}
      <input class="form-control" name="mass" bind:value={sample_line_item.mass} on:input={ handleSave } />
    { :else }
      {sample_line_item.mass}
    { /if }
  </td>
  <td>
    { #if editable}
      <input class="form-control" name="methanol_volume" bind:value={sample_line_item.methanol_volume} on:input={ handleSave } />
    { :else }
      { sample_line_item.methanol_volume }
    { /if }
  </td>
  <td>
    { #if editable}
      <input class="form-control" name="moisture" bind:value={sample_line_item.moisture} on:input={ handleSave } />
    { :else }
      { sample_line_item.moisture }
    { /if }
  </td>
</tr>
