<script>
  import { onMount } from "svelte"

  export let step_count
  export let current_step

  onMount(() => {
    console.log('[Stepper] onMount invoked')
    console.log('[Stepper] step_count:', step_count)
    console.log('[Stepper] current_step:', current_step)
  })

</script>
<style>
.step {
  list-style: none;
  margin: .2rem 0;
  width: 100%;
}

.step .step-item {
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  margin-top: 0;
  min-height: 1rem;
  position: relative;
  text-align: center;
}

.step .step-item:not(:first-child)::before {
  background: #0069d9;
  content: "";
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.step .step-item a {
  color: #acb3c2;
  display: inline-block;
  padding: 20px 10px 0;
  text-decoration: none;
}

.step .step-item a::before {
  background: #0069d9;
  border: .1rem solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: .9rem;
  left: 50%;
  position: absolute;
  top: .2rem;
  transform: translateX(-50%);
  width: .9rem;
  z-index: 1;
}

.step .step-item.active a::before {
  background: #0069d9;
  border: .1rem solid #0069d9;
}

.step .step-item.active ~ .step-item::before {
  background: #e7e9ed;
}

.step .step-item.active ~ .step-item a::before {
  background: #e7e9ed;
}
</style>

<ul class="step d-flex flex-nowrap">
  {#each { length: step_count} as _, i}
    {#if current_step == (i + 1)}
      <li class="step-item active"><a href="">Step {i + 1}</li>
    {:else}
      <li class="step-item"><a href="">Step {i + 1}</li>
    {/if}
  {/each}
</ul>
