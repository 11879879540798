<script>
  import { onMount } from "svelte"
  import SampleDetailsPage from "./SampleDetailsPage.svelte"
  import SampleCountPage from "./SampleCountPage.svelte"
  import InvoicePage from "./InvoicePage.svelte"
  import Stepper from "../Stepper.svelte"
  import { pendingOrderId, selectOptions, sampleData, advancePageState } from "./pending_order_wizard_store.js"

  export let pending_order_id
  export let material_select_options
  export let pricing_select_options
  export let cannabis_type_select_options
  export let compliance_type_select_options

  let sample_data

  onMount(() => {
    console.log('[PendingOrderWizard] onMount invoked')
    selectOptions.set({
      material: material_select_options,
      pricing: pricing_select_options,
      cannabis_type: cannabis_type_select_options,
      compliance_type: compliance_type_select_options
    })
    pendingOrderId.set(pending_order_id)
  })

  function beforeUnload(event) {
      // event.preventDefault();
      // return event.returnValue = '';
  }

  sampleData.subscribe(value => {
    sample_data = value;
  });

  const pages = [/*SampleCountPage, */SampleDetailsPage, InvoicePage];

  // The current page of our form.
  let page = 0;

  // The state of all of our pages
  let pagesState = [];

  // Our handlers
  function onSubmit(values) {
    if (page === pages.length - 1) {
      // On our final page with POST our data somewhere
      console.log('sample_data: ', sample_data)
    } else {
      // If we're not on the last page, store our data and increase a step
      // pagesState[page] = values;
      // pagesState = pagesState; // Triggering update
      advancePageState(pending_order_id, page)
      page +=1;
      page
    }
  }

  function onBack(values) {
    if (page === 0) return;
    pagesState[page] = values;
    pagesState = pagesState; // Triggering update
    page -= 1;
  }

</script>

<div class="container">
  <div class="m-t-5 m-b-5 text-center">
    <h1>Create Order</h1>
    <Stepper step_count=3 current_step={page+1} />
  </div>
  <hr/>

  <!-- We display the current page here -->
  <svelte:component
   this={pages[page]}
   {onSubmit}
   {onBack}
   initialValues={pagesState[page]}
   />
</div>

<svelte:window on:beforeunload={beforeUnload}/>
