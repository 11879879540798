<link href='/css/fileuploader.css' rel='stylesheet' type='text/css'>
<LibLoader url="/js/fileuploader.js" on:loaded="{onLegacyJSLoaded}" />

<script>
  import LibLoader from './LibLoader.svelte';
  import { onMount } from "svelte"


  export let path
  export let upload_param
  export let railsModelId
  export let onUploadBegin
  export let auth_token
  export let callback = 'console.log'
  export let multiple = true
  export let maxFiles = 100
  export let buttonText = 'Upload a file'
  export let div_name = 'fireinc-uploader'
  export let allowedExtensions = "['jpg', 'jpeg', 'png', 'gif', 'mov', 'mp4','ogv']"

  let uploadsInProgress = 0;
  let uploader;


  function onLegacyJSLoaded() {
    console.log('[FileUploader]', 'qq.FileUploader:', qq.FileUploader);
    console.log('[FileUploader]', 'div_name:', div_name)
    console.log('[FileUploader]', 'document.getElementById(div_name):', document.getElementById(div_name))
    uploader = new qq.FileUploader({
      allowedExtensions: allowedExtensions,
      onComplete: onUploadComplete,
      onSubmit: onUploadSubmit,
      multiple: multiple,
      maxFiles: maxFiles,
      onProgress: function(id, fileName, loaded, total){},
      onCancel: onUploadCancel,
      showMessage: showMessage,
      element: document.getElementById(div_name),
      action: path,
      debug: true,
      // additional data to send, name-value pairs
      params: {
          railsModelId: railsModelId,
          authenticity_token: auth_token//,
          // upload_param: '#{upload_param}'
          // param2: 'value2'
      }
    }, buttonText);
  }

  onMount(() => {
    console.log('[FileUploader] onMount invoked')
  })


  function onUploadComplete(id, fileName, responseJSON){
    //alert("id:" + id + "\n\nfileName:\n"+fileName+"\n\nresponseJSON:\n"+responseJSON)
    uploadsInProgress--;
    if(uploadsInProgress == 0)
    {
      onAllUploadsComplete(responseJSON);
    }
  }
  function onUploadSubmit(id, fileName){
    console.log('onUploadSubmit invoked w/ id, fileName:', id, fileName)
    //alert("id:" + id + "\n\nfileName:\n"+fileName+"\n\nresponseJSON:\n"+responseJSON)
    uploadsInProgress++;
    eval(onUploadBegin + '({id, fileName, div_name, railsModelId })')
  }
  function onUploadCancel(id, fileName){
    //alert("id:" + id + "\n\nfileName:\n"+fileName+"\n\nresponseJSON:\n"+responseJSON)
    uploadsInProgress--;
  }
  function onUploadProgress(id, fileName, loaded, total)
  {

  }
  function onAllUploadsComplete(responseJSON)
  {
    console.log('onAllUploadsComplete invoked w/ responseJSON:', responseJSON)
    console.log('[FileUploader]', 'callback', callback)
    console.log('[FileUploader]', 'callback + "("+responseJSON+")"', callback + "("+responseJSON+")")
    eval(callback + '({...responseJSON, div_name, railsModelId })')

  }
  function showMessage(message, id, fileName)
  {
    alert(message)
    //             alert('file:'+ fileName + "\nid:" + id);
    // alert(jQuery('.qq-upload-list').index(0))
  }


</script>

<div id="{div_name}" />
