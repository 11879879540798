// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

window.Rails = Rails

// note: we pull in bootstrap from the theme, not from yarn
// assets (including bootstrap javascript) in public vender.min.js file

import WebpackerSvelte from "webpacker-svelte"

import LibLoader from "../src/components/LibLoader.svelte"

import SampleLineItemList from "../src/components/SampleLineItemList.svelte"
import FileUploader from "../src/components/FileUploader.svelte"
import Stepper from "../src/components/Stepper.svelte"
import PendingOrderWizard from "../src/components/pending_order_wizard/PendingOrderWizard.svelte"
// import NewSampleLineItemButton from "../src/components/NewSampleLineItemButton.svelte"
// import SampleLineItemCount from "../src/components/SampleLineItemCount.svelte"

WebpackerSvelte.setup({
  PendingOrderWizard,
  SampleLineItemList,
  FileUploader,
  Stepper
  // NewSampleLineItemButton,
  // SampleLineItemCount
})
