import { writable, derived } from "svelte/store"
import Api from "./api"

export const sampleLineItemStore = writable([])
export const batchDataStore =  writable([])
export const batchStateStore =  writable([])

export const sampleLineItemCountStore = derived(
  sampleLineItemStore,
  sampleLineItems => sampleLineItems.length
)

export const listSampleLineItems = async sample_line_item => {
  console.log('[stores.js] listSampleLineItems invoked w/ sample_line_item:', sample_line_item);
  const updatedSampleLineItems = await Api.get(`/batches/${sample_line_item.batch_id}/sample_line_items.json`)
  console.log('after update:')
  sampleLineItemStore.update(sample_line_item)
}


export const createSampleLineItem = async sample_line_item => {
  const createdSampleLineItem = await Api.post(`/batches/${sample_line_item.batch_id}/sample_line_items.json`, {
    sample_line_item
  })
  sampleLineItemStore.update(sampleLineItems => [...sampleLineItems, createdSampleLineItem])
}

export const saveSampleLineItem = async sample_line_item => {
  console.log('[stores.js] saveSampleLineItem invoked w/ sample_line_item:', sample_line_item);
  let result = await Api.put(`/batches/${sample_line_item.batch_id}/sample_line_items/${sample_line_item.id}.json`, { sample_line_item })
  console.log('[stores.js] result:' , result)

  batchDataStore.update( function(){ return { mass_complete: result.batch_mass_complete,
                                              methanol_volume_complete: result.batch_methanol_volume_complete,
                                              moisture_complete: result.batch_moisture_complete,
                                              sample_line_items_valid: result.batch_sample_line_items_valid } } );

  return result;
}
export const deleteSampleLineItem = sample_line_item => {
  sampleLineItemStore.update(sampleLineItems =>
    sampleLineItems.filter(({ id }) => id !== sample_line_item.id)
  )
  return Api.delete(`/batches/${sample_line_item.batch_id}/sample_line_items/${sample_line_item.id}.json`)
}



export const advanceBatchState = async batch_id => {
  console.log('[stores.js] advanceBatchState invoked w/ batch_id:', batch_id);
  let result = await Api.get(`/batches/${batch_id}/state/next.json`)
  console.log('[stores.js] result:' , result)
  batchStateStore.update( function(){
                            return { state: result.aasm_state,
                                     humanized: result.current_state_humanized }
                          } );

  return result;
}
